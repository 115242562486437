import { Route, Redirect } from "react-router-dom";
import React, {Suspense, lazy, Fragment } from 'react';
import { Container } from "reactstrap";
// const Navbar = lazy(() => import("./Components/Navbar"));
const Home = lazy(() => import("./Pages/Home"));
const Guide = lazy(() => import("./Pages/Guide"));

const App = () => {   
    return (
        <Fragment>
            {/* Components */}
            {/* <Navbar/> */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Loading...
                        </h6>
                    </div>
                </div>
            }>
              <Route path="/home" component={Home}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Loading...
                        </h6>
                    </div>
                </div>
            }>
              <Route path="/guide" component={Guide}/>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/home"/>
            )}/>
            <Container>
                <footer>
                    
                    <p>Copyright © {new Date().getFullYear()} <a title="Home" href="https://my.goodwillaz.org" target="_blank" rel="noreferrer">Goodwill of Central and Northern Arizona</a></p>
                </footer>
            </Container>
        </Fragment>
    )
};

export default App;